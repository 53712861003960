<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading || verifying" />
    </div>
    <location-bar
      :loading="loading"
      :with-filters="locationFilters"
      :initial-district="initialDistrict"
      @selected-country="adjustCountry"
      @selected-district="adjustDistrict"
    />
    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.location.value"
          :disabled="loading"
          :label="$t('trainings.trainings.inputs.locationInput')"
          :error="!!form.location.errors.length"
          :error-messages="translatedErrors(form.location.errors)"
          @blur="validate('location')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs6">
        <va-input
          color="info"
          v-model="zoom"
          :disabled="loading"
          :label="$t('trainings.trainings.inputs.zoomInput')"
        />
      </div>
      <div class="flex xs6">
        <va-input
          color="info"
          v-model="whatsapp"
          :disabled="loading"
          :label="$t('trainings.trainings.inputs.whatsappInput')"
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs8 md3">
        <va-date-picker
          id="init-input"
          v-model="trainingInitDate"
          :config="initDateConfig"
          :label="$t('trainings.trainings.inputs.initDateInput')"
        />
      </div>
      <div class="flex xs4 md1">
        <va-button
          flat
          small
          icon="fa fa-times"
          type="button"
          @click.prevent="trainingInitDate = null"
        />
      </div>
      <div class="flex xs12 md3">
        <va-date-picker
          id="end-input"
          v-model="trainingEndDate"
          :config="endDateConfig"
          :label="$t('trainings.trainings.inputs.endDateInput')"
        />
      </div>
      <div class="flex xs4 md1">
        <va-button
          flat
          small
          icon="fa fa-times"
          type="button"
          @click.prevent="trainingEndDate = null"
        />
      </div>
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedOrganizer"
          :label="$t('trainings.trainings.inputs.organizerInput')"
          :options="organizersList"
          :loading="isLoading.organizers"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-select
          v-model="selectedType"
          :label="$t('trainings.trainings.inputs.typeInput')"
          :options="typesList"
          :loading="isLoading.types"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.training_story.value"
          type="textarea"
          :min-rows="5"
          :autosize="true"
          :disabled="loading"
          :label="$t('trainings.trainings.inputs.storyInput')"
          :error="!!form.training_story.errors.length"
          :error-messages="translatedErrors(form.training_story.errors)"
          @blur="validate('training_story')"
        />
      </div>
    </div>

    <div
      class="row"
      v-if="!wizard"
    >
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading || notValid || verifying"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')

export default {
  name: 'training-form',
  components: {
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    wizard: {
      type: Boolean,
      default: function () { return false },
    },
    training: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    notValid () {
      return !this.selectedOrganizer ||
        this.noRegionalData ||
        !this.selectedType
    },
    isNew () {
      return this.training.id === 0
    },
    initDateConfig () {
      return {
        maxDate: this.trainingEndDate,
      }
    },
    endDateConfig () {
      return {
        minDate: this.trainingInitDate,
      }
    },
  },
  data () {
    return {
      verifying: false,
      assignedError: false,
      noRegionalData: false,
      locationFilters: ['regions', 'countries', 'districts'],
      isLoading: {
        organizers: false,
        types: false,
      },
      isError: {
        organizers: false,
        types: false,
      },
      trainingInitDate: null,
      trainingEndDate: null,
      initialDistrict: null,
      selectedCountry: null,
      selectedDistrict: null,
      organizersList: [],
      selectedOrganizer: '',
      typesList: [],
      selectedType: '',
      zoom: '',
      whatsapp: '',
      form: {
        location: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
        training_story: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    training (val) {
      this.setTraining(val)
    },
    selectedCountry (val) {
      this.checkRegionalData()
    },
    selectedDistrict (val) {
      this.checkRegionalData()
    },
    currentLocale (val) {
      this.validateAll()
      this.asyncFindTrainingTypes('', true)
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setTraining (val) {
      this.setFormData(val)
      if (val.organizer) {
        this.selectedOrganizer = val.organizer
      }

      if (val.district) {
        const district = Object.assign({}, val.district)
        this.selectedDistrict = district
        this.initialDistrict = district
        if (val.district.country) {
          const country = Object.assign({}, val.district.country)
          this.selectedCountry = country
        }
      }

      if (val.description) {
        this.descriptionContent = val.description
      }

      if (val.training_type) {
        this.selectedType = val.training_type
      }

      if (val.end_date) {
        this.trainingEndDate = val.end_date
      }
      if (val.init_date) {
        this.trainingInitDate = val.init_date
      }
      if (val.whatsapp) {
        this.whatsapp = val.whatsapp
      }
      if (val.zoom) {
        this.zoom = val.zoom
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    checkRegionalData () {
      this.noRegionalData = !this.selectedCountry || !this.selectedDistrict
    },
    initialData () {
      if (this.training) {
        this.setTraining(this.training)
      }

      this.asyncFindOrganizer('')
      this.asyncFindTrainingTypes('')
    },
    async asyncFindTrainingTypes (query, reselect) {
      if (this.currentUser.can('TrainingTypes', 'index')) {
        await this.asyncFind(query, 'types', 'trainings/types')
        if (this.isError.types) {
          return this.asyncFindTrainingTypes(query)
        }
      } else if (this.selectedType !== null) {
        this.typesList = [
          this.selectedType,
        ]
      }

      if (this.selectedType === null && this.training.training_type_id) {
        const type = this.typesList.find(t => t.id === this.training.training_type_id)
        this.selectedType = type || this.selectedType
      } else if (reselect) {
        const type = this.typesList.find(t => t.id === this.selectedType.id)
        this.selectedType = type || this.selectedType
      }
    },
    async asyncFindOrganizer (query) {
      await this.asyncUsers(query, 'organizers', 'users')
      if (this.isError.organizers) {
        this.asyncFindOrganizer(query)
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }
      const r = `/${route}?q=${query}`
      let response = false
      try {
        response = await this.$http.get(r)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }
      if (!query.includes('&trainer=')) {
        query += '&trainer=true'
      }

      const r = `/${route}?group=${query}`
      let response = false
      try {
        response = await this.$http.get(r)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    adjustCountry (country) {
      this.selectedCountry = country
    },
    adjustDistrict (district) {
      this.selectedDistrict = district
    },
    hasErrors () {
      let errors = false
      if (!this.isNew) {
        if (this.selectedOrganizer === '') {
          this.showToast(this.$t('notifications.auth.organizer_needed'), {
            icon: 'fa-times',
            position: 'top-right',
          })
          errors = true
        }
      }

      if (this.selectedDistrict === '') {
        this.showToast(this.$t('notifications.errors.district_needed'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        errors = true
      }

      return errors
    },
    async submit () {
      this.validateAll()
      if (!this.formReady || this.hasErrors()) return

      const training = this.getFormData(this.training)

      this.$emit('selected-country', this.selectedCountry)
      training.district_id = this.selectedDistrict.id
      if (this.selectedOrganizer) {
        training.organizer_id = this.selectedOrganizer.id
      }
      if (this.selectedType) {
        training.training_type_id = this.selectedType.id
      }
      training.init_date = this.trainingInitDate
      training.end_date = this.trainingEndDate
      training.whatsapp = this.whatsapp
      training.zoom = this.zoom

      delete training.photos
      delete training.district
      delete training.trainers
      delete training.organizer
      delete training.submitter
      delete training.dcpi_role
      delete training.participants
      delete training.photos_number
      delete training.trainers_number
      delete training.participants_number
      delete training.training_type

      this.assignedError = false
      this.$emit('submit', training)
    },
  },
}
</script>
